.app--content {
  padding-top: 70px;
  min-height: calc(100vh - 303px);
}

.app--padded {
  padding: 16px;
}

a {
  color: #a48eff;
  font-weight: 700;
  text-decoration: none;
}
