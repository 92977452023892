html {
  background-color: #13141e;
}

body,
#root {
  margin: 0;
  padding: 0;
  font-family: Nunito, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  background-color: #13141e;
}

html,
body,
#root {
  height: 100%;
  font-size: 16px !important;
}

html,
body,
input,
textarea,
buttons {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

.valign-middle {
  display: inline-flex;
  vertical-align: middle;
}

.Aligner {
  display: flex;
  align-items: center;
  min-height: 24em;
  justify-content: center;
}

.Aligner-item {
  flex: 1;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

.Aligner-item--fixed {
  flex: none;
  max-width: 50%;
}

.czy--reset-link,
.czy--reset-link:visited,
.czy--reset-link:active,
.czy--reset-link:hover {
  color: inherit;
  text-decoration: none;
}

.czy-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-left: 8px; /* 16 = gutter */
  padding-right: 8px; /* 16 = gutter */
  margin: auto;
}

/*
$mui-screen-sm-min: 544px !default;
$mui-screen-md-min: 768px !default;
$mui-screen-lg-min: 992px !default;
$mui-screen-xl-min: 1200px !default;

$mui-container-sm:               570px !default;
$mui-container-md:               740px !default;
$mui-container-lg:               960px !default;
$mui-container-xl:               1170px !default;
*/

@media (min-width: 544px/* $mui-screen-sm-min */) {
  .czy-container {
    max-width: 570px /* $mui-container-sm */;
  }
}

@media (min-width: 768px/* $mui-screen-md-min */) {
  .czy-container {
    max-width: 740px /* $mui-container-md */;
  }
}
@media (min-width: 992px/* $mui-screen-lg-min */) {
  .czy-container {
    max-width: 960px /* $mui-container-lg */;
  }
}
@media (min-width: 1200px/* $mui-screen-xl-min */) {
  .czy-container {
    max-width: 1170px /* $mui-container-xl */;
  }
}

.czy-clearfix:before,
.czy-clearfix:after {
  content: ' ';
  display: table;
}
.czy-clearfix:after {
  clear: both;
}
